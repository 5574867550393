<template>
  <div>
    <h1>CTEs</h1>
    <v-btn
      @click="$router.push('/adicionar-cte')"
      color="success"
      class="mb-2"
      title="Adicionar CTe"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>

    <v-btn
      @click="handleUpdateList()"
      color="secondary"
      class="mb-2 ml-2"
      :loading="loading"
      title="Atualizar lista"
    >
      <v-icon> mdi-refresh </v-icon>
    </v-btn>

    <v-btn
      @click="$router.push('/consulta-cte')"
      color="warning"
      class="mb-2 ml-2"
      title="Consulta"
    >
      <v-icon> mdi-folder-search </v-icon>
    </v-btn>

    <v-btn
      @click="$router.push('/romaneios-completo')"
      color="error"
      class="mb-2 ml-2"
      title="Romaneios"
    >
      <v-icon> mdi-truck </v-icon>
    </v-btn>

    <v-btn
      @click="calendarOpened = true"
      color="primary"
      class="mb-2 ml-2"
      title="Abrir Calendário"
    >
      <v-icon> mdi-calendar </v-icon>
    </v-btn>

    <v-btn
      @click="$router.push('/entregas-regiao')"
      color="light-green"
      class="mb-2 ml-2"
      title="Entregas por Região"
    >
      <v-icon> mdi-map </v-icon>
    </v-btn>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisar"
      single-line
      hide-details
      class="ma-4"
    ></v-text-field>

    <div class="d-none d-lg-flex flex-wrap justify-space-arround mb-4">
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.ok"
        @click="handleClicked"
        data-filter="ok"
        >Em dia</span
      >
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.hoje"
        @click="handleClicked"
        data-filter="today"
        >Previsão hoje</span
      >
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.atrasadas"
        @click="handleClicked"
        data-filter="late"
        >Atrasado</span
      >
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.rota"
        @click="handleClicked"
        data-filter="delivering"
        >Em rota</span
      >
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.retido"
        @click="handleClicked"
        data-filter="recount"
        >CTe retido</span
      >
      <span
        class="subtitle"
        :style="'background: ' + $store.state.colors.ocorrencia"
        @click="handleClicked"
        data-filter="occurrence"
        >Com ocorrência</span
      >
      <v-slider
        :hint="`Altura Tabela: ${$store.state.tableSize}%`"
        :value="$store.state.tableSize"
        min="50"
        persistent-hint
        @change="handleTableSize"
      ></v-slider>
    </div>
    <v-data-table
      :items="formatedCtes"
      :headers="headers"
      class="elevation-1"
      dense
      :loading="loading"
      :item-class="rowColor"
      :search="search"
      @update:items-per-page="handleUpdateItemsPerPage"
      fixed-header
      :height="`${$store.state.tableSize}vh`"
      disable-pagination
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item`]="{ item }">
        <tr :style="`background: ${rowColor(item)}`">
          <td>{{ item.partners.name }}</td>
          <td>
            <a
              :title="'Entrega em ' + item.recipients.cities.name"
              :href="`/#/editar-cte/${item.id}`"
              target="_blank"
            >
              {{ item.number }}
            </a>
          </td>
          <td>
            <span :title="item.nf">{{ cutNF(item.nf) }}</span>
          </td>
          <td>
            <span
              :title="
                item.recipients.name + ' - ' + item.recipients.cities.name
              "
            >
              <a target="_blank" :href="`/#/clientes/${item.recipients.id}`">{{
                cutName(item.recipients.name)
              }}</a>
            </span>
          </td>
          <td>{{ item.arrival }}</td>
          <td>{{ item.dpe }}</td>
          <td>
            {{ handleDeliveryDate(item) }}
          </td>
          <td style="min-width: 100px">
            <div class="cte-actions">
              <confirm-delivery @saved="getCtes()" :cte="item" />
              <v-icon
                v-if="item.schedules.length > 0"
                :class="`mx-2 ${
                  item.schedules[0] && item.schedules[0].date === today
                    ? 'blink'
                    : ''
                }`"
                :title="handleFormatDate(item)"
              >
                mdi-clock
              </v-icon>
            </div>
          </td>
          <td v-if="false">
            <div class="cte-actions">
              <span>
                <v-icon
                  title="Lançar ocorrência"
                  @click="handleOccurrence(item)"
                  :color="hasOccurrence(item.occurrences) ? 'error' : ''"
                  >mdi-alert</v-icon
                >
              </span>
              <span>
                <v-icon
                  title="Ver/Editar"
                  @click="$router.push('/editar-cte/' + item.id)"
                  >mdi-pencil</v-icon
                >
              </span>
              <span v-if="item.palletizations.length < 1">
                <add-palletization @saved="getCtes" :cte="item" />
              </span>
              <span v-if="item.palletizations.length > 0">
                <print-palletization :cte="item" />
              </span>
              <span>
                <add-scheduling @schedulingSaved="getCtes()" :cte="item" />
              </span>
              <span>
                <v-icon
                  @click="handleSetDelivered(item)"
                  title="Marcar como entregue"
                >
                  mdi-check
                </v-icon>
              </span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="isEditingScheduling"> </v-dialog>

    <div v-if="isSetDelivered">
      <v-dialog v-model="isSetDelivered">
        <set-delivered
          @deliveryDateSaved="handleSaveDeliveryDate"
          :cte="focusCte"
        />
      </v-dialog>
    </div>

    <div v-if="isAddingOccurrence">
      <v-dialog v-model="isAddingOccurrence">
        <add-occurrence
          @occurrenceSaved="occurrenceSaved('close')"
          :cte="focusCte"
        />
      </v-dialog>
    </div>

    <div v-if="calendarOpened">
      <v-dialog v-model="calendarOpened">
        <calendar />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import moment from "moment";
import AddPalletization from "../components/AddPalletization";
import AddScheduling from "../components/AddScheduling";
import setDelivered from "../components/setDelivered";
import addOccurrence from "../components/AddOccurrence";
import printPalletization from "../components/PrintPalletization";
import calendar from "../components/SchedulesCalendar";
import ConfirmDelivery from "../components/confirmDelivery.vue";

export default {
  components: {
    AddPalletization,
    AddScheduling,
    setDelivered,
    addOccurrence,
    printPalletization,
    calendar,
    ConfirmDelivery,
  },
  watch: {
    search() {
      this.$store.dispatch("set_ctes_search", this.search);
    },
  },
  computed: {
    formatedCtes() {
      return this.ctes.map((e) => {
        e.arrival = moment(e.arrival).format("DD/MM/YYYY");
        e.dpe = moment(e.dpe).format("DD/MM/YYYY");
        return e;
      });
    },
  },
  methods: {
    handleTableSize(size) {
      this.$store.dispatch("set_table_size", size);
    },
    handleDeliveryDate({ deliveries }) {
      return deliveries[0]
        ? moment(deliveries[0].delivering.date, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : "";
    },
    handleFormatDate(item) {
      return `${moment(item.schedules[0].date).format("DD/MM/YYYY")} - ${moment(
        item.schedules[0].time,
        "HH:mm:ss"
      ).format("HH:mm")}`;
    },
    handleUpdateList() {
      this.getCtes();

      let subs = document.querySelectorAll(".subtitle");

      subs.forEach((sub) => {
        sub.classList.remove("clicked");
      });
    },
    handleClicked(event) {
      if (event.target.classList.contains("clicked")) {
        event.target.classList.remove("clicked");
        this.filters[event.target.dataset.filter](false);
      } else {
        let subs = document.querySelectorAll(".subtitle");

        subs.forEach((sub) => {
          sub.classList.remove("clicked");
        });

        event.target.classList.add("clicked");
        this.filters[event.target.dataset.filter]();
      }
    },
    handleUpdateItemsPerPage(e) {
      this.$store.dispatch("set_items_per_page", e);
    },
    hasOccurrence(occurrence) {
      if (occurrence.length < 1) {
        return false;
      }
      let has = false;
      occurrence.forEach((e) => {
        if (e.closed != true) {
          has = true;
        }
      });
      return has;
    },
    occurrenceSaved() {
      (this.isAddingOccurrence = false), this.getCtes();
    },
    handleOccurrence(cte) {
      this.isAddingOccurrence = true;
      this.focusCte = cte;
    },
    dateEquals(e) {
      if (
        moment(e.arrival, "DD/MM/YYYY").format("YYYY-MM-DD") >=
        moment(e.dpe, "DD/MM/YYYY").format("YYYY-MM-DD")
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleSaveDeliveryDate() {
      this.isSetDelivered = false;
      this.getCtes();
    },
    handleSetDelivered(cte) {
      this.isSetDelivered = true;
      this.focusCte = cte;
    },
    addScheduling(item) {
      if (item.schedules.length > 0) {
        this.$router.push("/editar-cte/" + item.id);
        return;
      }

      this.scheduling = item;
      this.isAddingScheduling = true;
    },
    editScheduling(item) {
      this.scheduling = item;
      this.isEditingScheduling = true;
    },
    cutName(name) {
      let ref_num = 20;
      if (name.length > ref_num) {
        return name.substring(0, ref_num - 3) + "...";
      } else {
        return name;
      }
    },
    cutNF(nf) {
      let ref_num = 10;
      if (nf.length > ref_num) {
        return nf.substring(0, ref_num - 3) + "...";
      } else {
        return nf;
      }
    },
    rowColor(item) {
      if (this.verifyRecount(item)) {
        return this.$store.state.colors.retido;
      }
      if (this.verifyDelivering(item)) {
        return this.$store.state.colors.rota;
      }
      if (this.verifyOccurrence(item)) {
        return this.$store.state.colors.ocorrencia;
      }
      if (this.verifyToday(item, true)) {
        return this.$store.state.colors.hoje;
      }
      if (this.verifyLate(item, true)) {
        return this.$store.state.colors.atrasadas;
      }
      if (this.verifyOk(item, true)) {
        return this.$store.state.colors.ok;
      }
    },
    getCtes() {
      this.loading = true;
      http
        .get("api/ctes")
        .then((res) => {
          this.loading = false;
          this.ctes = res.data;
          this.allCtes = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateCtes() {
      let ctes = this.$http.get("api/ctes");
      return ctes;
    },
    load(status = true) {
      this.loading = status;
    },
    verifyOk(cte, isColor = false) {
      let ok = true;

      cte.occurrences.forEach((occurrence) => {
        if (occurrence.closed === 0) {
          ok = false;
        }
      });

      if (isColor) {
        if (moment(cte.dpe, "DD/MM/YYYY").format("YYYY-MM-DD") < this.today) {
          if (cte.schedules[0]) {
            if (cte.schedules[0].date < this.today) {
              ok = false;
            }
          } else {
            ok = false;
          }
        }
      } else {
        if (cte.dpe < this.today) {
          if (cte.schedules[0]) {
            if (cte.schedules[0].date < this.today) {
              ok = false;
            }
          } else {
            ok = false;
          }
        }
      }

      return ok;
    },
    verifyToday(cte, isColor = false) {
      let today = false;

      if (isColor) {
        if (
          moment(cte.dpe, "DD/MM/YYYY").format("YYYY-MM-DD") === this.today ||
          (cte.schedules[0] && cte.schedules[0].date === this.today)
        ) {
          today = true;
        }
      } else {
        if (
          cte.dpe === this.today ||
          (cte.schedules[0] && cte.schedules[0].date === this.today)
        ) {
          today = true;
        }
      }

      if (cte.schedules[0] && cte.schedules[0].date > this.today) {
        today = false;
      }

      return today;
    },
    verifyLate(cte, isColor = false) {
      let late = false;

      if (isColor) {
        if (
          moment(cte.dpe, "DD/MM/YYYY").format("YYYY-MM-DD") < this.today ||
          (cte.schedules[0] && cte.schedules[0].date < this.today)
        ) {
          late = true;
        }
      } else {
        if (
          cte.dpe < this.today ||
          (cte.schedules[0] && cte.schedules[0].date < this.today)
        ) {
          late = true;
        }
      }

      if (cte.schedules[0] && cte.schedules[0].date >= this.today) {
        late = false;
      }

      return late;
    },
    verifyDelivering(cte) {
      let delivering = false;

      if (cte.deliveries.length > 0) {
        if (cte.deliveries[0].delivering.date === this.today) {
          delivering = true;
        }
      }

      return delivering;
    },
    verifyRecount(cte) {
      let recount = false;

      cte.occurrences.forEach((occurrence) => {
        if (occurrence.occurrence_type_id === 7 && occurrence.closed === 0) {
          recount = true;
        }
      });

      return recount;
    },
    verifyOccurrence(cte) {
      let occurrences = false;

      cte.occurrences.forEach((occurrence) => {
        if (occurrence.closed === 0) {
          occurrences = true;
        }
      });

      return occurrences;
    },
  },
  mounted() {
    this.getCtes();
  },
  data() {
    return {
      filterActive: false,
      tableSize: 70,
      filters: {
        ok: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyOk(r);
          });
        },
        today: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyToday(r);
          });
        },
        late: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyLate(r);
          });
        },
        delivering: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyDelivering(r);
          });
        },
        recount: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyRecount(r);
          });
        },
        occurrence: async (active = true) => {
          this.load();

          let res = await this.updateCtes();

          this.load(false);

          if (!active) {
            this.ctes = res.data;

            return;
          }

          this.ctes = res.data.filter((r) => {
            return this.verifyOccurrence(r);
          });
        },
      },
      calendarOpened: false,
      isAddingOccurrence: false,
      isSetDelivered: false,
      search: this.$store.state.ctesSearch ? this.$store.state.ctesSearch : "",
      today: new Date().toISOString().slice(0, 10),
      isEditingScheduling: false,
      isAddingScheduling: false,
      scheduling: {},
      status: "",
      cardClass: "",
      focusCte: {},
      loading: false,
      ctes: [],
      allCtes: [],
      headers: [
        { text: "Parceira", value: "partners.name" },
        { text: "CTe", value: "number" },
        { text: "NFe", value: "nf" },
        { text: "Destinatário", value: "recipients.name" },
        { text: "Chegada", value: "arrival" },
        { text: "Previsão", value: "dpe" },
        { text: "Rota", value: "deliveries" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.cte-card p {
  font-size: 18px;
}
.cte-card {
  background-color: rgb(181, 190, 201);
}

.cte-actions {
  display: flex;
  flex-wrap: wrap;
}

.blink {
  animation: blinker 1s linear infinite;
}

.subtitle {
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 10px;
  box-shadow: -1px -1px 3px white, 1px 1px 3px #454545;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.clicked {
  box-shadow: inset -1px -1px 3px white, inset 1px 1px 3px #454545;
  transform: translate(2px, 2px);
}

@keyframes blinker {
  50% {
    background-color: red;
  }
}
</style>
